$title-font: 'IBM Plex Sans', sans-serif;
//$title-font: 'Source Sans Pro', sans-serif;
$subtitle-font: 'IBM Plex Sans', sans-serif;
$body-font: 'IBM Plex Sans', sans-serif;

$main900: #000033;
$main700: #000058;
$main400: #000073;
$accent900: #616161;
$accent700: #757575;
$accent400: #BDBDBD;
$text-color: #363636;
$text-color-secondary: #4e4e4e;
$text-color-inverse: #fafafa;
$background-color: #fcfcfc;
$backdrop-color: rgba(250, 250, 250, 0.4);
//$navbar-color: #fafafa;
$navbar-color: #ffffff;
$sidebar-background: #eeeff0;
$item-background: white;
$element-background: #eeeeee;
$element-background-light: #f3f3f3;
$input-background: #eceff1;
$active: $main400;

$status-color-green: #43a047;
$status-color-yellow: #fbc02d;
$status-color-red: #D32F2F;

$view-max-width: 900px;

$animation-time: .4s;
$animation-timing-function: cubic-bezier(.4, 0, .2, 1);

$element-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$element-shadow-hover: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

$primary: $main900;
$secondary: $accent700;
$enable-rounded: false;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1540px
) !default;

$btn-box-shadow: none;
$btn-active-box-shadow: none;
$input-btn-focus-box-shadow: none;
$link-hover-decoration: none;
$input-box-shadow: none;

$border-radius: 3px;
$enable-rounded: true;

$info: #bababa;

$input-disabled-bg: #f4f4f4;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

/* login-icons */
$material-icons-font-path: "/assets/material-icons" !default;
$login-icons-font-path: "/assets/login-icons" !default;
$social-icons-font-path: "/assets/social-icons" !default;

/* vertical rythm */
$space-xl: 40px;
$space-lg: 40px;
$space-md: 25px;
$space-sm: 15px;
@mixin vertical-space-top {
  @include media-breakpoint-up(xl) {
    margin-top: $space-xl;
  }
  @include media-breakpoint-only(lg) {
    margin-top: $space-lg;
  }
  @include media-breakpoint-only(md) {
    margin-top: $space-md;
  }
  @include media-breakpoint-down(sm) {
    margin-top: $space-sm;
  }
  @media print {
    margin-top: 5px;
  }
}

@mixin vertical-space-down {
  @include media-breakpoint-up(xl) {
    margin-bottom: $space-xl;
  }
  @include media-breakpoint-only(lg) {
    margin-bottom: $space-lg;
  }
  @include media-breakpoint-only(md) {
    margin-bottom: $space-md;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: $space-sm;
  }
}

h1 {
  font-family: $title-font;
  font-weight: 700;
  color: $text-color;
}

h2 {
  font-family: $title-font;
  font-weight: 600;
  color: $text-color-secondary;
}

h3 {
  font-family: $title-font;
  color: $text-color-secondary;
}

p, span, div {
  font-family: $body-font;
  color: $text-color;
}
