@import url('https://fonts.bunny.net/css?family=IBM+Plex+Sans:400,600,700&display=swap');
@import 'variables';

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

main {
  overflow-x: hidden;
}

html, body {
  background-color: $background-color;
  min-height: 100vh;
}

h1, h2, h3 {
  font-family: $title-font;
  margin-bottom: 8px;
  margin-top: 8px;
}

a {
  color: $main700;
  text-decoration: none !important;

  &:hover {
    color: $main900;
  }
}

// BREADCRUMBS //
/*.breadcrumb-item {
  display: flex;
  align-items: flex-start;
}
.breadcrumb-item + .breadcrumb-item::before {
  height: 24px;
  content: url(assets/nav-icons/right.svg) !important;
  font-weight: 600!important;
}*/

// CONSTANTS //
.content {
  @include vertical-space-top;

  display: flex !important;
  flex-direction: column;

  @include media-breakpoint-up(xl) {
    min-height: calc(100vh - 60px);
  }
  @include media-breakpoint-only(lg) {
    min-height: calc(100vh - 60px);
  }
  @include media-breakpoint-only(md) {
    min-height: calc(100vh - 25px - 60px);
  }
  @include media-breakpoint-only(sm) {
    min-height: calc(100vh - 15px - 60px);
  }
  @include media-breakpoint-down(sm) {
    min-height: calc(100vh - 15px - 100px);
  }
}

.listing-content {
  max-width: 1140px;
  @include media-breakpoint-only(lg) {
    padding: 0 $space-lg;
  }
  @include media-breakpoint-only(md) {
    padding: 0 $space-md;
  }
}

.container-narrow {
  max-width: $view-max-width !important;
}

// OVERLAY FIXES FOR SEARCH //
.app-search, .app-dance-shoes {
  @include media-breakpoint-up(xl) {
    .overlay {
      display: none;
    }
  }
}

// CROSSBROWSER FIXES //
.btn:focus, .btn:active, .btn:active:focus {
  outline: none;
  box-shadow: none;
  //border: 2px solid transparent;
}

.btn-outline-primary, .btn-outline-secondary {
  &:focus, &:active {
    border-width: 1px!important;
    outline: none!important;
    box-shadow: none!important;
  }
}

.btn.btn-secondary, .btn.btn-secondary.active {
  box-shadow: none !important;
}

::selection, ::-moz-selection {
  background: $main400;
}

:focus {
  outline: none;
  box-shadow: none;
}

::-moz-focus-inner {
  border: 0;
  box-shadow: none;
}

::ng-deep {
  :focus {
    outline: none;
    box-shadow: none;
  }

  :hover {
    box-shadow: none;
  }

  ::-moz-focus-inner {
    border: 0;
    box-shadow: none;
  }
}

button.dropdown-toggle.btn {
  &:focus, &:active {
    outline: none !important;
    box-shadow: none !important;
    border-width: 1px;
  }
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: none;
}

app-icon {
  height: 24px;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
  box-shadow: none;
}

// BOOTSTRAP OVERRIDES //
.form-control:focus {
  border-color: $active;
}

// UTILITY //
.pointer {
  cursor: pointer;
}

ngb-tabset {
  .nav-link {
    background-color: $element-background;

    *, span, a[href] {
      color: transparentize($text-color-secondary, 0.05);
    }

    &:first-child {
      margin-left: 0;
      margin-right: 3px;
    }
  }

  .nav-link.active {
    background-color: transparent;
    *, span, a[href] {
      color: $text-color;
    }
  }
}
